<template>
    <q-page class="flex flex-center text-center">
        <div class="q-pa-md">
            <q-table
                title="GED"
                :rows="rows"
                :columns="columns"
                row-key="id"
                v-model:pagination="pagination"
                :loading="loading"
                :filter="filter"
                @request="onRequest"
                binary-state-sort
            >
                <template v-slot:top-right>
                    <!-- Fixed size, need improvement to be more responsive -->
                    <q-select style="width: 200px;" outlined v-model="filter_option_selected" :options="filter_options" label="Column" />
                    <q-input borderless dense debounce="300" class="q-ml-md" v-model="filter" placeholder="Search">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body-cell-download="props">
                    <q-td :props="props">
                        <q-btn icon="download" @click="onDownload(props.row)"></q-btn>
                    </q-td>
                </template>
            </q-table>
        </div>
    </q-page>
</template>

<style>
</style>

<script>
// TODO :
// - Improve search (plusieurs champs)
// - Add a button to delete a document
// - Add a button to update a document
// - Add a button to create a document

import { useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { ref } from '@vue/reactivity'

export default {
    name: 'GED',
    data () {
        return {
            columns: [
                { name: 'label', align: 'left', label: 'Label', field: 'label', sortable: true },
                { name: 'type', align: 'left', label: 'Type', field: 'type', sortable: true },
                { name: 'userid', align: 'left', label: 'Matricule', field: 'userid', sortable: true },
                { name: 'valid', align: 'left', label: 'Valid', field: 'valid', sortable: true },
                {
                    name: 'id',
                    required: true,
                    label: 'ID',
                    align: 'left',
                    field: 'id',
                    sortable: true
                },
                { name: 'download', align: 'left', label: 'Download', sortable: false },
            ],
            // Filter id don't work because type UUID
            filter_options: ['id', 'label', 'type', 'valid', 'matricule']
        }
    },
    setup() {
        const route = useRoute()
        const quasar = useQuasar()
        const ged_url = process.env.VUE_APP_GED_ENDPOINT
        let loading = ref(false)
        let filter_option_selected = ref('matricule')
        let filter = ''
        let rows = ref([])
        let pagination = ref({
            sortBy: 'id',
            descending: false,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 10
        })

        if (route.params.matricule) {
            filter_option_selected.value = 'matricule'
            filter = route.params.matricule
        }

        const onDownload = async (row) => {
            const blob = await (await fetch(ged_url + `/document/${row.uuid}/data`, {
                method: 'GET',
                headers: {
                    'token': localStorage.getItem('token')
                }
            })).blob()
            const file = window.URL.createObjectURL(blob)
            window.open(file, '_blank') // Open in new tab
        }

        const getDocuments = async (startRow, count, filter, filter_option_selected, sortBy, descending) => {
            return await (await fetch(
                ged_url + `/documents?startRow=${startRow}&count=${count}${sortBy ? `&orderBy=${sortBy}&orderDirection=${descending ? 'desc' : 'asc'}` : ''}${filter ? `&${filter_option_selected === 'matricule' ? 'iduser' : filter_option_selected}=${filter}` : ''}`,
                {
                method: 'GET',
                headers: {
                    'token': localStorage.getItem('token')
                }
            })).json()
        }

        const getDocumentsCount = async (filter, filter_option_selected) => {
            return await (await fetch(ged_url + `/documentsCount${filter ? `?${filter_option_selected === 'matricule' ? 'iduser' : filter_option_selected}=${filter}` : ''}`, {
                method: 'GET',
                headers: {
                    'token': localStorage.getItem('token')
                }
            })).json()
        }

        const onRequest = async (props) => {
            const { page, rowsPerPage, sortBy, descending } = props.pagination
            const filter = props.filter
            loading.value = true
            pagination.value.rowsNumber = await getDocumentsCount(filter, filter_option_selected.value).catch(() => {
                loading.value = false
                quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: 'You don\'t have the good permissions...',
                    position: 'top',
                    timeout: 5000
                })
            })
            if (loading.value === false) {
                return
            }
            // get all rows if "All" (0) is selected
            const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
            const startRow = (page - 1) * rowsPerPage

            const returnedData = await getDocuments(startRow, fetchCount, filter, filter_option_selected.value, sortBy, descending)
            rows.value.splice(0, rows.value.length, ...returnedData)

            pagination.value.page = page
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        }

        onRequest({
            pagination: pagination.value,
            filter: filter
        })

        return {
            quasar,
            pagination,
            loading,
            rows,
            ged_url,
            filter_option_selected,
            filter,
            onDownload,
            onRequest,
            getDocuments,
            getDocumentsCount,
        }
    }
}
</script>
